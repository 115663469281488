/** Webix classes */

/*

    .webix_dtable - container with DataTable
    .webix_ss_header - container with the header
    .webix_ss_header .webix_hcell - a cell of the header
    .webix_ss_header .webix_first - the first cell of the header
    .webix_ss_header .webix_last - the last cell of the header
    .webix_ss_footer - container with the footer
    .webix_ss_footer .webix_hcell - a cell of the footer
    .webix_ss_footer .webix_first - the first cell of the footer
    .webix_ss_footer .webix_last - the last cell of the footer
    .webix_ss_body - container with the table body
    .webix_column - a column of the table
    .webix_column .webix_last - the last column of the table
    .webix_column .webix_first - the first column of the table
    .webix_column .webix_cell - a cell of the column

    Border in the configuration options
    - css:"webix_data_border",
    - css:"webix_header_border",
    - css:"webix_footer_border"


*/


/** Should be in the theming for the font family and size */
.webix_view,
.webix_ss_header,
.webix_ss_header TD {
    font-family: 'Montserrat';
}

.webix_hcell {
    font-weight: bold;
}

.webix_ss_body {
    font-size: 90%;
}